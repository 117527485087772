@import "../../theme/shared.scss";

.ewp-web-dialog {
  --height: auto;
  --width: auto;
  --min-width: 968px;
  --border-radius: 5px;
  --box-shadow: none;
  &.small {
    --min-width: 697px;
  }
  &::part(content) {
    max-width: 100%;
    width: auto;
    max-height: 90vh;
    position: relative;
    overflow-y: auto;
    .modal-content {
      overflow: auto;
    }
    // .ion-page {

    //   contain: content;

    // }
  }
  .ewp-dialog-header-item {
    --inner-padding-end: 0;
    --border-color: var(--ion-color-light-10);
    .ewp-web-dialog-header-title {
      margin: 23px 24px 20px 24px;
    }
    .ewp-web-dialog-header-close {
      margin: 23px 24px 20px 24px;
    }
  }
  .ewp-web-dialog-children-container {
    margin: 40px 60px 42px;
    &.tradesmen {
      margin: 20px 60px 42px;
    }
  }
}
