@import "../../theme/shared.scss";

.ewp-web-dropdown-select-list-item {
  --background: var(--ion-color-tertiary);
  --min-height: 56px;
  position: relative;
  box-shadow: 0 0 20px 0 rgba(14, 12, 34, 0.05);
  width: 100%;
  border-radius: 4px;
  &.small {
    --min-height: 30px;
  }

  .ewp-web-dropdown-label {
    position: absolute;
    top: 7px;
    z-index: -1;
  }

  .ewp-web-dropdown-selected-label {
    &.has-error {
      color: var(--ion-color-danger) !important;
    }
    &.has-label-and-data {
      padding-top: 15px;
      color: var(--ion-color-dark) !important;
    }
    &.is-read-only {
      opacity: 0.5 !important;
    }
    z-index: -1;
  }

  .ewp-web-dropdown-select-item-icon {
    margin-right: 15px;
    height: 14px;
    width: 14px;
    z-index: -1;
  }
  color: var(--ion-color-light);
  &.has-value {
    color: var(--ion-color-dark) !important;
  }
  &.has-error {
    color: var(--ion-color-danger) !important;
    border: 1px solid var(--ion-color-danger) !important;
  }
}

.ewp-web-dropdown-select-popover-container {
  --max-height: 400px;
  margin-top: 8px;
  --width: 472px;
  &.full-dialog-form {
    --width: 600px !important;
  }
  &.half-dialog-form {
    --width: 413px !important;
  }
  &.page-form {
    --width: 600px !important;
  }
  &.mobile-dropdown {
    --width: 320px !important;
    margin-left: 20px;
  }

  &::part(content) {
    --background: var(--ion-color-tertiary);
    box-shadow: 0 0 20px 0 rgba(14, 12, 34, 0.05);
  }
  .ewp-web-dropdown-select-popover-list {
    background: var(--ion-color-tertiary);
    padding-bottom: 150px;
    .ewp-dropdown-xero-section {
      .xero-details-section {
        padding-bottom: 10px;
        border-bottom: 1px solid var(--ion-color-light-50);
      }
    }
    .ewp-web-dropdown-select-popover-list-item {
      --background: var(--ion-color-tertiary);
      display: flex;
      align-items: center;
      &.no-padding {
        margin: 0;
        height: 31px;
      }
      .ewp-web-dropdown-select-popover-list-label {
        &.has-padding-top {
          padding-top: 17px;
          padding-bottom: 17px;
        }
      }
      .truck-preferred-label {
        background-color: var(--ion-color-event-complete);
        color: var(--ion-color-success);
        font-size: 8px;
        max-width: 55px;
        border-radius: 2px;
        height: 16px;
        text-align: center;
        padding-top: 3px;
      }
      .truck-not-available-label {
        background-color: var(--ion-color-event-incomplete);
        color: var(--ion-color-danger);
        font-size: 8px;
        max-width: 55px;
        border-radius: 2px;
        height: 16px;
        text-align: center;
        padding-top: 3px;
        margin-left: 5px;
      }
      .xero-invoice-status-label {
        background-color: var(--ion-color-event-warning);
        color: var(--ion-color-warning);
        font-size: 8px;
        max-width: 55px;
        border-radius: 2px;
        height: 16px;
        text-align: center;
        padding-top: 3px;

        // &.AUTHORISED {
        // }// use default
        &.PAID {
          border: 1px solid var(--ion-color-success);
          color: 1px solid var(--ion-color-success);
          background-color: var(--ion-color-event-complete);
        }
        &.DRAFT {
          background-color: var(--ion-color-light-10);
          color: var(--ion-color-dark);
        }
        &.VOIDED {
          background-color: var(--ion-color-event-incomplete);
          color: var(--ion-color-danger);
        }
        &.DELETED,
        &.DUE {
          background-color: var(--ion-color-event-incomplete);
          color: var(--ion-color-danger);
          margin-left: 3px;
        }
      }
      .ewp-web-dropdown-select-popover-list-subtitle {
        --background-color: var(--ion-color-tertiary);
      }
      .ewp-web-dropdown-popover-checkbox {
        right: 0;
        height: 20px;
        width: 20px;
      }
    }
  }

  .ewp-web-dropdown-searchbar-list-container {
    margin: 19px;
    --background: var(--ion-color-tertiary);
    overflow-y: auto;
    .ewp-web-dropdown-searchbar {
      --background: var(--ion-color-tertiary);
      max-width: 100%;
      align-self: flex-end;
      margin-top: 10px;
      border: 1px solid var(--ion-color-light-10);
      border-radius: 4px;
      input {
        box-shadow: none !important;
      }
      &.searchbar-has-focus {
        border: 1px solid var(--ion-color-primary);
      }
    }
  }
}

.ewp-web-dropdown-selected-items-container {
  height: 183px;
  width: 100%;
  margin-top: 20px;
  background: var(--ion-color-tertiary);
  overflow-x: auto;
  .ewp-web-dropdown-selected-items-list {
    background: transparent;
    height: 100%;
    max-height: 183px;
    padding: 24px;
    overflow-y: auto;
    .ewp-web-dropdown-selected-items-col {
      padding-right: 20px;
      .ewp-web-dropdown-selected-items-item {
        width: 100%;
        height: 100%;
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        --border-radius: 5px;

        .ewp-web-dropdown-initials-container {
          height: $ewpIconHeight;
          width: $ewpIconWidth;
          margin-right: 10px;
          .ewp-web-dropdown-initials {
            background: var(--ion-color-light);
            border-radius: 50%;
            height: $ewpIconHeight;
            width: $ewpIconWidth;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .ewp-web-dropdown-item-picture {
          height: $ewpIconHeight;
          width: $ewpIconWidth;
          margin-right: 10px;
        }
        .ewp-web-dropdown-name-sub-name-container {
          padding-top: 10px;
          padding-bottom: 10px;
          .ewp-web-dropdown-label {
            overflow: hidden;
            text-overflow: ellipsis;
            width: 170px;
          }
        }
        .ewp-web-dropdown-selected-items-close-button {
          right: 0px;
          position: absolute;
          margin-right: 20px;
        }
      }
    }
  }
}
