@import "../../theme/shared.scss";

.close-ticket-faults-defects-row {
  .close-ticket-faults-defects-only-col {
    margin-top: 20px;
  }
  .close-ticket-faults-defects-start-col {
    padding-right: 10px;
    margin-top: 20px;
  }
  .close-ticket-faults-defects-end-col {
    padding-left: 10px;
    margin-top: 20px;
  }
}

.ticket-attachment-container {
  width: 100px;
  position: relative;
  // padding-right: 10px;
  .ticket-attachment-avatar-button {
    height: 100px;
    width: 100px;
    margin-top: 25px;

    .ticket-attachment-avatar {
      --size: 100px;
      --border-radius: 5px;
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }
}

.close-ticket-faults-defects-calendar-container {
  margin-top: 15px;

  .close-ticket-faults-defects-action-button {
    border-radius: 5px;
    height: 56px;
    width: 100%;
    margin-top: 20px;
    &.date-button {
      --background: var(--ion-color-white);
      --background-hover: var(--ion-color-light-15);
      --background-activated: var(--ion-color-gray-shade);
      --box-shadow: none;
      --border-width: 1px;
      --border-style: solid;
      --border-color: var(--ion-color-light-10);
      --border-radius: 4px;
    }
    .close-ticket-faults-defects-calendar-icon {
      height: 18px;
      width: 18px;
      position: absolute;
      left: 13px;
      background: url("../../assets/icons/calendar.svg") no-repeat center center;
    }
    .close-ticket-faults-defects-calendar-text {
      position: absolute;
      left: 40px;
    }
  }
}
.close-ticket-faults-defects-upload-container {
  margin-top: 30px;
  width: 968px;
  &.is-tablet {
    width: 100%;
  }
  .close-ticket-faults-defects-title-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    .close-ticket-faults-defects-attachments-add-another-button {
      width: 140px;
      height: 30px;
      border-radius: 4px;
      background: var(--ion-color-gray);
      .close-ticket-faults-defects-small-add-icon {
        margin-left: 10px;
        position: absolute;
        left: 0;
      }
    }
  }
  .web-upload-list-attachment-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: auto;
    height: 100%;
    width: 100%;
    .web-upload-attachment-container {
      width: 105px;
      position: relative;
      padding-top: 5px;
      .remove-attachment-button {
        height: 24px;
        z-index: 1;
        right: 0;
        --border-radius: 50% !important;
        position: absolute;
        width: 24px;
        --box-shadow: none !important;
        margin-right: 3px;
        .web-upload-attachment-close-icon {
          position: absolute;
          font-size: 18px;
        }
      }
      .web-upload-attachment-avatar {
        --size: 95px;
        --border-radius: 5px;
        margin-top: 10px;
        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
    }
  }

  .close-ticket-faults-defects-upload-item {
    --background: rgba(78, 84, 94, 0.05);
    border-radius: 4px;
    border: 1px dashed rgba(118, 116, 128, 0.25);
    height: 100px;
    margin-top: 20px;
    .web-upload-icon-label-container {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100px;
      margin-left: 320px;
      .close-ticket-faults-defects-upload-label {
        cursor: pointer;
      }
      .close-ticket-faults-defects-upload-icon {
        margin-right: 12px;
        height: 23px;
        width: 23px;
        background: url("../../assets/icons/web-upload.svg") no-repeat center
          center;
      }
    }
  }
}
.close-ticket-faults-defects-delete-button {
  height: 44px;
  width: 183px;
  background: var(--ion-color-gray);
  margin-right: 18px;
  border-radius: 5px;
  --box-shadow: none;
}

.close-ticket-faults-defects-delete-dialog::part(content) {
  --border-radius: 5px;
  height: 275px;
  width: 697px;
  box-shadow: 0px 5px 20px 0 rgba(14, 12, 34, 0.05);
  .close-ticket-faults-defects-delete-dialog-content {
    --padding-top: 60px;
    --padding-end: 60px;
    --padding-bottom: 42px;
    --padding-start: 60px;

    .close-ticket-faults-defects-delete-dialog-close-button-dialog {
      position: absolute;
      height: 18px;
      width: 18px;
      top: 24px;
      right: 24px;
    }

    .close-ticket-faults-defects-delete-dialog-title-container {
      display: flex;
      flex-direction: column;
      .close-ticket-faults-defects-delete-dialog-subtitle {
        margin-top: 13px;
      }
    }
    .close-ticket-faults-defects-delete-dialog-action-buttons-item {
      margin-top: 60px;
    }
  }
}
