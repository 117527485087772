ion-picker {
  .picker-wrapper {
    position: absolute;
    top: 0;
  }
}

.ewp-timepicker-action-button {
  --border-radius: 5px;
  height: 56px;
  width: 226px;
  text-align: start;
  &.has-error {
    color: var(--ion-color-danger) !important;
    --border-color: var(--ion-color-danger) !important;
  }

  .ewp-timepicker-label {
    width: 100%;
    padding-left: 16px;
  }
  .ewp-timepicker {
    padding-top: 16px;
    padding-left: 11px;
    height: 56px;
    width: 226px;
    --border-color: var(--ion-color-light-10);

    &.has-error {
      color: var(--ion-color-danger) !important;
      --border-color: var(--ion-color-danger) !important;
    }
  }

  &.date-button {
    --background: var(--ion-color-white);
    --background-hover: var(--ion-color-light-15);
    --background-activated: var(--ion-color-gray-shade);
    --box-shadow: none;
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--ion-color-light-10);
    --border-radius: 4px;
  }

  &.ewp-timepicker-today-button {
    margin-right: 20px;
    width: 72px;
  }

  &.ewp-timepicker-calendar-button {
    .ewp-timepicker-calendar-text {
      position: absolute;
      left: 0;
      margin-left: 10px;
      pointer-events: none;
    }
  }
  .ewp-timepicker-time-icon {
    height: 20px;
    width: 20px;
    position: absolute;
    right: 0px;
    padding-right: 40px;
    background: url("../../assets/icons/time.svg") no-repeat center center;
  }
}

.time-picker-dialog.time-picker-dialog {
  &::part(content) {
    --border-radius: 5px;
    height: 270px;
    width: 350px;
    box-shadow: 0px 5px 20px 0 rgba(14, 12, 34, 0.05);
  }
  .time-picker-dialog-content {
    display: flex;

    .timer-picker-main-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      align-content: stretch;
      justify-content: space-between;
      .time-picker-close-button-dialog {
        position: absolute;
        height: 18px;
        width: 18px;
        top: 24px;
        right: 24px;
      }

      .time-picker-dialog-fields-container {
        // margin-top: 20px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .time-picker-dialog-field-container {
          display: flex;
          flex-direction: column;
          align-items: center;

          ion-icon {
            width: 40px;
            height: 40px;
          }

          .time-picker-dialog-field {
            border: 2px solid var(--ion-color-gray);
            border-radius: 5px;
          }
        }
      }

      .time-picker-dialog-action-button-container {
        display: flex;
        justify-content: space-between;

        // .time-picker-dialog-cancel-button {
        // }
        .time-picker-dialog-submit-button {
          width: 100%;
          max-width: 150px;
        }
      }
    }
  }
}
