@import "../../theme/shared.scss";

.faults-and-defects-row {
  .faults-and-defects-start-col {
    padding-right: 10px;
    margin-top: 20px;
  }
  .faults-and-defects-end-col {
    padding-left: 10px;
    margin-top: 20px;
    .service-date-input-date {
      width: 100%;
    }
  }
  .faults-and-defects-full-col {
    margin-top: 20px;
    .defect-truck-dropdown-container {
      width: 100%;
      // max-width: 400px;
      margin: 20px 0px 0px 0px;
    }
  }
}

.faults-and-defects-dialog-upload-container {
  margin-top: 30px;
  width: 100%;
  .faults-and-defects-dialog-title-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    .faults-and-defects-dialog-attachments-add-another-button {
      width: 140px;
      height: 30px;
      border-radius: 4px;
      background: var(--ion-color-gray);
      .faults-and-defects-dialog-small-add-icon {
        margin-left: 10px;
        position: absolute;
        left: 0;
      }
    }
  }
  .web-upload-list-attachment-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: auto;
    height: 100%;
    width: 100%;
    .web-upload-attachment-container {
      width: 105px;
      position: relative;
      padding-top: 5px;
      .remove-attachment-button {
        height: 24px;
        z-index: 1;
        right: 0;
        --border-radius: 50% !important;
        position: absolute;
        width: 24px;
        --box-shadow: none !important;
        margin-right: 3px;
        .web-upload-attachment-close-icon {
          position: absolute;
          font-size: 18px;
        }
      }
      .web-upload-attachment-avatar {
        --size: 95px;
        --border-radius: 5px;
        margin-top: 10px;
        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
    }
  }

  .faults-and-defects-dialog-upload-item {
    --background: rgba(78, 84, 94, 0.05);
    border-radius: 4px;
    border: 1px dashed rgba(118, 116, 128, 0.25);
    height: 100px;
    margin-top: 20px;
    .web-upload-icon-label-container {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100px;
      margin-left: 280px;
      .faults-and-defects-dialog-upload-label {
        cursor: pointer;
      }
      .faults-and-defects-dialog-upload-icon {
        margin-right: 12px;
        height: 23px;
        width: 23px;
        background: url("../../assets/icons/web-upload.svg") no-repeat center
          center;
      }
    }
  }
}

.faults-and-defects-delete-button {
  height: 44px;
  width: 183px;
  background: var(--ion-color-gray);
  margin-right: 18px;
  border-radius: 5px;
  --box-shadow: none;
}

.faults-and-defects-delete-dialog::part(content) {
  --border-radius: 5px;
  height: 275px;
  width: 697px;
  box-shadow: 0px 5px 20px 0 rgba(14, 12, 34, 0.05);
  .faults-and-defects-delete-dialog-content {
    --padding-top: 60px;
    --padding-end: 60px;
    --padding-bottom: 42px;
    --padding-start: 60px;

    .faults-and-defects-delete-dialog-close-button-dialog {
      position: absolute;
      height: 18px;
      width: 18px;
      top: 24px;
      right: 24px;
    }

    .faults-and-defects-delete-dialog-title-container {
      display: flex;
      flex-direction: column;
      .faults-and-defects-delete-dialog-subtitle {
        margin-top: 13px;
      }
    }
    .faults-and-defects-delete-dialog-action-buttons-item {
      margin-top: 60px;
    }
  }
}
