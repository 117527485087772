.attachment-file-common-tooltip {
  position: fixed;
  padding: 10px;
  // display: none;
  // height: 50px;
  // width: 200px;
  // left: 588px;
  // top: 700px;
  background-color: #333;
  color: #fff;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 99999999999;
}
