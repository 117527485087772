@import "../../theme/shared.scss";

.request-parts-row {
  .request-parts-only-col {
    margin-top: 20px;
  }
  .request-parts-start-col {
    padding-right: 10px;
    margin-top: 20px;
  }
  .request-parts-end-col {
    padding-left: 10px;
    margin-top: 20px;
  }
}

.request-parts-delete-button {
  height: 44px;
  width: 183px;
  background: var(--ion-color-gray);
  margin-right: 18px;
  border-radius: 5px;
  --box-shadow: none;
}

.request-parts-delete-dialog::part(content) {
  --border-radius: 5px;
  height: 275px;
  width: 600px;
  box-shadow: 0px 5px 20px 0 rgba(14, 12, 34, 0.05);
  .request-parts-delete-dialog-content {
    --padding-top: 60px;
    --padding-end: 60px;
    --padding-bottom: 42px;
    --padding-start: 60px;

    .request-parts-delete-dialog-close-button-dialog {
      position: absolute;
      height: 18px;
      width: 18px;
      top: 24px;
      right: 24px;
    }

    .request-parts-delete-dialog-title-container {
      display: flex;
      flex-direction: column;
      .request-parts-delete-dialog-subtitle {
        margin-top: 13px;
      }
    }
    .request-parts-delete-dialog-action-buttons-item {
      margin-top: 60px;
    }
  }
}
