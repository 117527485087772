.nav-content-grid-container {
  height: 100%;

  .nav-xero-expired-header {
    padding: 5px 20px;
    background-color: var(--ion-color-tertiary);

    ion-label {
      display: flex;
      align-items: center;
      ion-button {
        margin-left: 5px;
      }
    }
  }
  .nav-xero-error-header {
    padding: 5px 20px;
    background-color: var(--ion-color-event-incomplete);
  }
  .nav-content-row-container {
    height: 100%;
  }
  .nav-content-col-container {
    padding: 0;
    height: auto;
    background-color: var(--ion-color-tertiary);
    width: 256px !important;
    max-width: 256px !important;
  }

  .home-container {
    padding: 40px 20px 0px 40px;
    &.is-tablet {
      padding: 30px 20px 0px 30px;
    }
  }
}
