.job-sched-weekly-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  // align-items: flex-start;
  // justify-content: center;
  background-color: var(--ion-color-tertiary);
  padding: 5px;
  padding-bottom: 250px;
  // overflow-y: auto;
  .job-sched-spinner {
    width: 40%;
    height: 40%;
  }
  .job-sched-weekly-grid {
    border: 1px solid var(--ion-color-light-10);
    width: 100%;
    &.header {
      max-height: 35px;
    }

    .job-sched-weekly-row {
      border-bottom: 1px solid var(--ion-color-light-10);
      .job-sched-weekly-col {
        border-left: 1px solid var(--ion-color-light-10);
        &.header,
        &.name {
          text-align: center;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &:first-child {
          border-left: unset;
        }
      }
    }
  }
  .job-sched-weekly-main {
    width: 100%;
    max-height: 70vh;
    overflow-y: auto;
    padding-bottom: 500px;

    .job-sched-weekly-grid {
      border: 1px solid var(--ion-color-light-10);
      width: 100%;

      .job-sched-weekly-row {
        border-bottom: 1px solid var(--ion-color-light-10);
        .job-sched-weekly-col-container {
          display: flex;
          flex-direction: column;
          border-left: 1px solid var(--ion-color-light-10);
          .job-sched-weekly-col-div {
            // border-left: 1px solid var(--ion-color-light-10);
            min-height: 80px;
            &.header,
            &.name {
              text-align: center;
              height: 35px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            &:first-child {
              border-left: unset;
            }
            &.has-job {
              background: red;
              cursor: pointer;
              min-height: 80px;

              &.start-date {
                position: relative;
              }

              &.day {
                background-color: var(--ion-color-event-day-shift) !important;
                &.start-date {
                  border-left: 4px solid var(--ion-color-day-shift) !important;
                }
              }
              &.night {
                background-color: var(--ion-color-event-night-shift) !important;
                &.start-date {
                  border-left: 4px solid var(--ion-color-night-shift) !important;
                }
              }

              &.completed {
                background-color: var(--ion-color-event-complete) !important;
                &.start-date {
                  border-left: 4px solid var(--ion-color-primary) !important;
                }
              }
              &.incomplete {
                background-color: pink !important;
                &.start-date {
                  border-left: 4px solid palevioletred !important;
                }
              }
              &.cancelled {
                background-color: var(--ion-color-event-cancelled) !important;
                &.start-date {
                  border-left: 4px solid var(--ion-color-danger) !important;
                }
              }
              &.pencilled {
                background-color: var(--ion-color-light-50) !important;
                &.start-date {
                  border-left: 4px solid var(--ion-color-light) !important;
                }
              }

              .job-sched-truck-grid {
                position: absolute;
                width: 100%;
                z-index: 2;
                .job-sched-truck-grid-row {
                  .job-sched-truck-grid-col {
                    display: flex;
                    flex-direction: column;
                    &.details {
                      padding-left: 5px;
                    }
                  }
                  .ewp-event-week-label {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                  }
                  .job-schedule-calendar-status-container {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    padding-right: 5px;
                    height: 100%;
                    justify-content: space-around;
                    // padding: 5px 0px;
                    .ewp-remote-icon {
                      height: 25px;
                      width: 25px;
                      &.day {
                        color: var(--ion-color-day-shift) !important;
                        &.completed {
                          color: var(--ion-color-success) !important;
                        }
                      }
                      &.night {
                        color: var(--ion-color-night-shift) !important;
                        &.completed {
                          color: var(--ion-color-success) !important;
                        }
                      }
                    }
                    .ewp-notif-status-icon {
                      height: 25px;
                      width: 25px;
                      &.day {
                        color: var(--ion-color-day-shift) !important;
                        &.accepted {
                          color: var(--ion-color-success) !important;
                        }
                        &.pending {
                          color: var(--ion-color-warning) !important;
                        }
                      }
                      &.night {
                        color: var(--ion-color-night-shift) !important;
                        &.accepted {
                          color: var(--ion-color-success) !important;
                        }
                        &.pending {
                          color: var(--ion-color-warning) !important;
                        }
                      }
                    }

                    .ewp-invoice-icon {
                      &.week {
                        height: 25px;
                        width: 25px;
                      }
                    }

                    .admin-notes-container {
                      position: relative;
                      ion-icon {
                        width: 25px;
                        height: 25px;
                      }
                      .notes-counter {
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 13px;
                        height: 13px;
                        background: red;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
