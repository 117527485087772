.admin-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  .admin-list-table-container {
    margin-top: 10px;
    .MuiTableCell-stickyHeader {
      z-index: 9999 !important;
    }
    .admin-list-name-container {
      height: 62px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .admin-list-initials-container {
        height: 45px;
        width: 45px;
        margin-right: 10px;

        .admin-list-initials {
          background: var(--ion-color-light);
          border-radius: 50%;
          height: 40px;
          width: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .admin-list-picture {
        height: 45px;
        width: 45px;
        margin-right: 10px;
        img {
          height: 45px;
          width: 45px;
        }
      }
      .admin-list-name-email-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
    .admin-list-status-and-button-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .admin-list-status-label {
        color: var(--ion-color-light) !important;
        &.danger {
          color: var(--ion-color-danger) !important;
        }
      }
      .admin-list-edit-button {
        width: 110px;
        height: 30px;
      }
      .admin-list-resend-button {
        width: 110px;
        height: 30px;
        margin-right: 20px;
        --box-shadow: none;
      }
    }
  }
}

.confirm-cancel-invite-dialog::part(content) {
  --border-radius: 5px;
  height: 296px;
  width: 697px;
  box-shadow: 0px 5px 20px 0 rgba(14, 12, 34, 0.05);

  .confirm-cancel-invite-content {
    --padding-top: 50px;
    --padding-end: 60px;
    --padding-bottom: 42px;
    --padding-start: 60px;

    .confirm-cancel-invite-close-button-dialog {
      position: absolute;
      height: 18px;
      width: 18px;
      top: 24px;
      right: 24px;
    }

    .confirm-cancel-invite-title-container {
      display: flex;
      flex-direction: column;

      .confirm-cancel-invite-subtitle {
        margin-top: 13px;
      }
    }

    .confirm-cancel-invite-action-buttons-item {
      margin-top: 50px;
    }
  }
}
