@import "./shared.scss";

* {
  letter-spacing: -0.2px !important;
}

.text-no-wrap {
  white-space: nowrap;
}

.text-break-spaces {
  white-space: break-spaces !important;
}

.global-flex {
  display: flex;
}
//SITE STATUS
.ewp-site-status-icon {
  height: 8px;
  width: 8px;
  margin-right: 6px;
  border-radius: 50%;

  &.blacklisted {
    background-color: var(--ion-color-dark);
  }
  &.offsite {
    background-color: var(--ion-color-light);
  }
  &.signed-on {
    background-color: var(--ion-color-success);
  }
  &.pending {
    background-color: var(--ion-color-warning);
  }
  &.not-inducted {
    background-color: var(--ion-color-danger);
  }
}

//LINK TYPES
.ewp-footer-links {
  letter-spacing: $ewpLinkLetterSpacing;
  text-align: $ewpLinkTextAlign;
  line-height: $ewpSmallLinkLineHeight;
  width: 100px;
}

.ewp-links {
  font-size: $ewpBodyFontSize;
  font-weight: $ewpBodyFontSize;
  color: $ewpLinkColor;
  letter-spacing: $ewpLinkLetterSpacing;
  text-align: $ewpLinkTextAlign;
  height: $ewpLinkHeight;
  vertical-align: bottom;
  margin: 0;
  --padding-start: 0;
  --padding-end: 0;
  --border-radius: 0;
}

// LABEL TYPES
.ewp-large-label {
  font-family: $ewpFontName !important;
  font-size: $ewpFontSizeLarge !important;
  font-weight: bold;
  letter-spacing: $ewpSmallLabelLetterSpacing;
  height: $ewpLargeLabelHeight;
  vertical-align: bottom;
}

.ewp-stat-detail-label {
  font-size: $ewpH6FontSize !important;
  font-weight: $ewpH6FontWeight !important;
  color: var(--ion-color-light) !important;
  opacity: 0.5 !important;

  &.dark {
    opacity: 1 !important;
  }
}

//INPUT TYPES
.ewp-input {
  border: 1px solid var(--ion-color-light);
  border-radius: 5px;
  --padding-start: 20px !important;
  --padding-end: 20px;
  max-width: 760px;
  line-height: 36px;
  &.has-focus {
    border-color: var(--ion-color-primary) !important;
  }

  &.md {
    margin-top: 5px;
  }
}

.ewp-web-confirm-pin-field {
  border-radius: 4px;
  --padding-start: 20px !important;
  --padding-end: 20px;
  max-width: 760px;
  &.has-focus {
    border: 1px solid #ffb42b;
    border-color: #ffb42b !important;
    background-color: var(--ion-color-tertiary) !important;
    opacity: 1 !important;
  }
  &.md {
    margin-top: 5px;
  }
}

.ewp-user-input-item {
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-end: 0;
}

.ewp-input-label {
  position: absolute;
  padding-left: 20px;
  bottom: 40px;
}

.ewp-mobile-number-item {
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-end: 0;
  .ewp-mobile-number-input {
    background-color: var(--ion-color-tertiary);
    border-radius: 4px !important;
    --padding-start: 16px !important;
    --padding-end: 20px;
    max-width: 597px;
    max-height: 56px;
    line-height: 36px;
    font-size: $ewpBodyFontSize;
    box-shadow: 0px 5px 20px 0 rgba(14, 12, 34, 0.05);
  }
}

.ewp-input-error-container {
  text-align: start;

  .ewp-input-error-msg {
    position: absolute;
    padding-left: 16px;
    line-height: 19px !important;
    font-size: $ewpParagraphFontSize !important;
    font-weight: $ewpParagraphFontWeight !important;
    color: var(--ion-color-danger) !important;
  }
}

.ewp-has-error-class {
  border: 1px solid var(--ion-color-danger);
}

.ewp-stripe-input {
  background-color: var(--ion-color-tertiary);
  border-radius: 4px !important;
  box-shadow: 0 0 20px 0 rgba(14, 12, 34, 0.05);
  height: 56px;
  padding: 18px;

  &.StripeElement--focus {
    border: 1px solid var(--ion-color-primary) !important;
  }

  &.StripeElement--invalid {
    border: 1px solid var(--ion-color-danger) !important;
  }
}

// .ewp-input-item-container {
//   --padding-start: 0;
//   --padding-end: 0;
//   --inner-padding-end: 0;
//   box-shadow: 0 0 20px 0 rgba(14, 12, 34, 0.05);
//   border-radius: 4px;
//   &.readOnly {
//     box-shadow: none !important;
//   }
//   &.item-has-value {
//     .ewp-web-label-input {
//       color: var(--ion-color-primary);
//     }
//   }
//   &.item-has-error-class {
//     .ewp-web-label-input {
//       color: var(--ion-color-danger);
//     }
//   }
//   &.item-has-focus {
//     .ewp-web-label-input {
//       color: var(--ion-color-primary);
//     }
//   }
//   .ewp-web-label-input {
//     opacity: 1 !important;
//     z-index: 9999;
//     position: absolute;
//     padding-left: 20px;
//     padding-top: 0px;
//     line-height: 19px;
//     color: var(--ion-color-light);
//     margin-top: 0px !important;
//     &.mobile {
//       margin-top: 0px !important;
//     }
//   }

//   .ewp-web-input {
//     background-color: var(--ion-color-tertiary);
//     border-radius: 4px !important;
//     --padding-start: 16px !important;
//     --padding-end: 20px;
//     --padding-top: 23px !important;
//     max-width: 100%;
//     height: 56px;
//     line-height: $ewpBodyLineHeight;
//     font-size: $ewpBodyFontSize;
//     font-weight: $ewpBodyFontWeight;
//     input {
//       height: 100%;
//     }
//     &.has-value {
//       --padding-start: 15px !important;
//       --padding-bottom: 8px !important;
//     }
//     &.has-error-class {
//       border: 1px solid var(--ion-color-danger) !important;
//       --padding-start: 14px !important;
//       --padding-bottom: 8px !important;
//     }
//     &.has-focus {
//       border: 1px solid var(--ion-color-primary) !important;
//       --padding-start: 14px !important;
//       --padding-bottom: 8px !important;
//     }

//     &.searchbar-has-value {
//       .searchbar-input-container {
//         .searchbar-input {
//           padding-top: 23px !important;
//           padding-left: 15px !important;
//         }
//       }
//     }
//     &.searchbar-has-error-class {
//       border: 1px solid var(--ion-color-danger) !important;
//       --placeholder-color: var(--ion-color-danger) !important;
//       .searchbar-input-container {
//         .searchbar-input {
//           padding-left: 14px !important;
//         }
//       }
//     }
//     &.searchbar-has-focus {
//       border: 1px solid var(--ion-color-primary) !important;
//       .searchbar-input-container {
//         .searchbar-input {
//           --placeholder-opacity: 0 !important; // hide placeholder if on focus
//           padding-top: 23px !important;
//           padding-left: 14px !important;
//         }
//       }
//     }

//     &.md {
//       margin-top: 0px;
//     }

//     input.native-input[disabled] {
//       opacity: 0.5 !important;
//     }
//   }
// }

//BUTTON TYPES
// Can't override a ionic-variable using a variable hense direct use of 35px
.ewp-large-web-button {
  height: 100%;
  width: 100%;
  max-width: 597px;
  max-height: 67px;
  font-size: $ewpH3FontSize;
  font-weight: $ewpH3FontWeight;
  margin-top: 70px;
  --padding-top: 24px;
  --padding-bottom: 24px;
  --box-shadow: none;
  --border-radius: 5px;
}

.ewp-small-web-button {
  font-size: $ewpWebSmallButtonFontSize !important;
  font-weight: $ewpWebSmallButtonFontWeight !important;
  width: 179px;
  height: 54px;
  z-index: 9999;
  --box-shadow: none;
}

.ewp-extra-small-web-button {
  --border-radius: 5px;
  font-size: $ewpH6FontSize !important;
  font-weight: $ewpH6FontWeight !important;
  width: 120px;
  height: 30px;
  z-index: 9999;
  --box-shadow: none;
}

.ewp-create-web-button {
  height: 44px;
  width: 226px;
  font-size: $ewpH5FontSize !important;
  font-weight: $ewpH5FontWeight !important;
  --border-radius: 4px;
  --padding-right: 0px;
  --box-shadow: none;
}

.ewp-web-gray-button {
  --background: var(--ion-color-gray);
  --background-hover: var(--ion-color-gray-tint);
  --background-activated: var(--ion-color-gray-shade);
  --box-shadow: none;
  &.onFocus {
    --background: var(--ion-color-primary) !important;
    --background-hover: var(--ion-color-primary-tint) !important;
    --background-activated: var(--ion-color-primary-shade) !important;
  }
}

.ewp-web-white-button {
  --background: var(--ion-color-white);
  --background-hover: var(--ion-color-light-15);
  --background-activated: var(--ion-color-gray-shade);
  --box-shadow: none;
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--ion-color-light-10);
  --border-radius: 4px;
  &.transparent {
    --background: transparent !important;
  }
}

//GLOBAL CHANGES TO ACCOMODATE DESIGN:
ion-card {
  box-shadow: 0 0 10px rgba(14, 12, 34, 0.05) !important;
}

ion-slides {
  user-select: auto !important;
}

//WEB ION CONTENT
.ewp-web-ion-content {
  --padding-start: 70px;
  --padding-end: 70px;
}

//WEB ITEM BUTTON
.ewp-item-no-horizontal-padding {
  --padding-start: 0;
  --inner-padding-end: 0;
  z-index: 0;
}

.ewp-button-padding-top {
  padding-top: 30px;
}

.ewp-save-form-button {
  z-index: 9999;
  height: 44px;
  width: 179px;
  margin-top: 50px;
  font-size: $ewpH5FontSize;
  font-weight: $ewpH5FontWeight;
  --border-radius: 5px;
  --padding-right: 0px;
  --box-shadow: none;
}

.ewp-cancel-button {
  margin-top: 50px;
  font-size: $ewpH4FontSize;
  font-weight: $ewpH4FontWeight;
  color: var(--ion-color-medium);
  --padding-start: 0;
}

//MODAL WEB
.ewp-modal-save-form-button {
  height: 44px !important;
  width: 179px !important;
  --padding-right: 0px;
  --border-radius: 5px;
  --background: var(--ion-color-primary);
  --color: var(--ion-color-white);
  --box-shadow: none;
  &.button-disabled {
    --background: var(--ion-color-light-15);
    --color: var(--ion-color-medium);
  }
}
.ewp-modal-cancel-button {
  --padding-start: 0;
}

//TEXT STYLES
.ewp-huge {
  font-size: $ewpHugeFontSize !important;
  font-weight: $ewpHugeFontWeight !important;
  color: var(--ion-color-medium) !important;
  line-height: $ewpHugeLineHeight !important;

  &.white {
    color: var(--ion-color-white) !important;
  }
}

.ewp-extra-huge {
  font-size: $ewpExtraHugeFontSize !important;
  font-weight: $ewpExtraHugeFontWeight !important;
  color: var(--ion-color-medium) !important;

  &.white {
    color: var(--ion-color-white) !important;
  }
}

.ewp-h1 {
  font-size: $ewpH1FontSize !important;
  font-weight: $ewpH1FontWeight !important;
  color: var(--ion-color-medium) !important;
  line-height: $ewpH1LineHeight !important;

  &.white {
    color: var(--ion-color-white) !important;
  }

  &.light-weight {
    font-weight: $ewpH1LightFontWeight !important;
  }

  &.bold {
    font-weight: $ewpH3FontBoldWeight !important;
  }
}

.ewp-h2 {
  font-size: $ewpH2FontSize !important;
  font-weight: $ewpH2FontWeight !important;
  color: var(--ion-color-medium) !important;
  line-height: $ewpH2LineHeight !important;

  &.white {
    color: var(--ion-color-white) !important;
  }
  &.bold {
    font-weight: $ewpH3FontBoldWeight !important;
  }
  &.primary {
    color: var(--ion-color-primary) !important;
  }
  &.success {
    color: var(--ion-color-success) !important;
  }
}

.ewp-h3 {
  font-size: $ewpH3FontSize !important;
  font-weight: $ewpH3FontWeight !important;
  color: var(--ion-color-medium) !important;
  line-height: $ewpH3LineHeight !important;

  &.white {
    color: var(--ion-color-white) !important;
  }
  &.primary {
    color: var(--ion-color-primary) !important;
  }
  &.bold {
    font-weight: $ewpH3FontBoldWeight !important;
  }
  &.small {
    font-size: $ewpH3FontSmallSize !important;
  }
  &.danger {
    color: var(--ion-color-danger) !important;
  }
}

.ewp-h4 {
  font-size: $ewpH4FontSize !important;
  font-weight: $ewpH4FontWeight !important;
  color: var(--ion-color-medium) !important;
  line-height: $ewpH4LineHeight !important;
  &.normal {
    font-weight: $ewpH4NormalFontWeight !important;
  }
  &.primary {
    color: var(--ion-color-primary) !important;
  }
  &.white {
    color: var(--ion-color-white) !important;
  }
  &.bold {
    font-weight: $ewpH3FontBoldWeight !important;
  }

  &.light {
    color: var(--ion-color-light) !important;
  }

  &.danger {
    color: var(--ion-color-danger) !important;
  }
}

.ewp-h5 {
  font-size: $ewpH5FontSize !important;
  font-weight: $ewpH5FontWeight !important;
  color: var(--ion-color-medium) !important;
  line-height: $ewpH5LineHeight !important;
  &.primary {
    color: var(--ion-color-primary) !important;
  }
  &.white {
    color: var(--ion-color-white) !important;
  }
  &.normal {
    font-weight: $ewpH5NormalFontWeight !important;
  }
  &.medium {
    color: var(--ion-color-medium) !important;
  }
  &.success {
    color: var(--ion-color-success) !important;
  }
  &.accepted {
    color: var(--ion-color-success) !important;
  }
  &.pending {
    color: var(--ion-color-warning) !important;
  }
  &.light {
    color: var(--ion-color-light) !important;
  }

  &.bold {
    font-weight: $ewpH5BoldFontWeight !important;
  }
  &.danger {
    color: var(--ion-color-danger) !important;
  }
}

.ewp-h6 {
  font-size: $ewpH6FontSize !important;
  font-weight: $ewpH6FontWeight !important;
  color: var(--ion-color-light) !important;
  line-height: $ewpH6LineHeight !important;
  &.small {
    font-size: $ewpH6SmallFontSize !important;
  }
  &.medium {
    font-size: 12px !important;
  }

  &.white {
    color: var(--ion-color-white) !important;
  }

  &.headerFont {
    font-size: 14px !important;
  }

  &.dark {
    color: var(--ion-color-medium) !important;
  }

  &.light {
    color: var(--ion-color-light) !important;
  }
  &.bold {
    font-weight: $ewpBold !important;
  }
  &.danger {
    color: var(--ion-color-danger) !important;
  }
  &.warning {
    color: var(--ion-color-warning) !important;
  }

  &.exp-warning {
    color: #ffa600 !important;
  }
}

.ewp-body {
  font-size: $ewpBodyFontSize !important;
  font-weight: $ewpBodyFontWeight !important;
  color: var(--ion-color-light) !important;
  line-height: $ewpBodyLineHeight !important;
  &.bold {
    font-weight: $ewpBold !important;
  }
  &.white {
    color: var(--ion-color-white) !important;
  }
  &.light {
    color: var(--ion-color-light) !important;
  }
  &.dark {
    color: var(--ion-color-dark) !important;
  }
}

.ewp-paragraph {
  font-size: $ewpParagraphFontSize !important;
  font-weight: $ewpParagraphFontWeight !important;
  color: var(--ion-color-light) !important;
  line-height: $ewpParagraphLineHeight !important;

  &.white {
    color: var(--ion-color-white) !important;
  }
  &.medium {
    color: var(--ion-color-medium) !important;
  }

  &.primary {
    color: var(--ion-color-primary) !important;
  }

  &.danger {
    color: var(--ion-color-danger) !important;
  }
  &.dark {
    color: var(--ion-color-medium) !important;
  }
  &.success {
    color: var(--ion-color-success) !important;
  }
  &.medium-weight {
    font-weight: $ewpParagraphFontWeightMedium !important;
  }
  &.bold {
    font-weight: $ewpBold !important;
  }
  &.italic {
    font-style: italic;
  }
  &.small {
    font-size: 11px !important;
  }
}

.ewp-temperature-huge {
  font-size: $ewpTemperatureHugeFontSize;
  font-weight: $ewpTemperatureHugeFontWeight;
  color: var(--ion-color-primary) !important;
}

.ck {
  background: var(--ion-color-white);
  border: none !important;
  transition-property: box-shadow, border;
  transition: 0.2s ease-in-out;
  max-width: inherit;
  max-height: inherit;
  height: inherit;
  width: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  margin: 0;
  border-radius: 4px !important;
  box-sizing: border-box;
  &.ck-content {
    padding-left: 20px !important;
    padding-right: 20px !important;
    max-width: inherit;
    max-height: inherit;
    height: inherit;
    width: inherit;
  }
  &.ck-focused {
    box-shadow: none !important;
    border: none !important;
    border-radius: 4px !important;
  }
  &.ck-blurred {
    border: none !important;
    max-width: inherit;
    max-height: inherit;
    height: inherit;
    width: inherit;
  }
}

.ewp-spinner {
  height: 50px;
  width: 50px;
}

.ewp-web-auth-company-rep-button {
  height: 100%;
  width: 100%;
  max-width: 474px;
  max-height: 68px;
  margin-top: 39px;
  --padding-top: 24px;
  --padding-bottom: 24px;
  --box-shadow: none;
  --border-radius: 5px;
}

.noselect {
  // pointer-events: none;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;

  &:focus {
    outline: none !important;
  }
}

.disabled-historical {
  opacity: 0.5;
  cursor: not-allowed;
}

.ewp-icon-label-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.ewp-warning-icon {
  background: url("../assets/icons/warning-icon.svg") no-repeat center center;
  height: 15px;
  width: 19px;
}

//LINK TYPES
.ewp-footer-links {
  letter-spacing: $ewpLinkLetterSpacing;
  text-align: $ewpLinkTextAlign;
  line-height: $ewpSmallLinkLineHeight;
  width: 100px;
}

.disabled-component {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.pac-container {
  box-shadow: none !important;
  border: 1px solid var(--ion-color-light-10) !important;
}

.note-card-container {
  max-width: 720px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.note-entry-action-button {
  height: 30px;
  width: 160px;
  margin-top: 20px;
  --border-radius: 4px;
  .note-entry-add-icon {
    height: 14px;
    width: 14px;
    position: absolute;
    left: 8px;
    background: url("../assets/icons/add-icon-black.svg") no-repeat center
      center;
  }
}
.table-link {
  color: black;
}

.label-as-link {
  cursor: pointer;
}

.attachment-file-link-container {
  margin-top: 10px;
  justify-content: flex-end;
  padding: 10px;
  text-align: center;
  a {
    width: 100%;
    height: 100%;
    font-size: 15px;
  }
}

.no-border-bottom {
  border-bottom: none !important;
}

::-webkit-scrollbar {
  display: none !important; // Safari and Chrome
}

.spacing {
  padding-right: 55px;
}
