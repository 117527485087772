.job-schedule-container {
  .job-schedule-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title-notifcation-container {
      display: flex;
      align-items: center;
      .notification-counter {
        position: absolute;
        left: 12px;
        top: 5px;
        z-index: 1;
        background: var(--ion-color-medium);
        border-radius: 50%;
        width: 17px;
        padding-right: 1px;
        height: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.has-notifications {
          background: var(--ion-color-danger);
        }
      }
    }
    .job-schedule-add-button {
      --border-radius: 5px;
      height: 54px;
      width: 200px;
      .job-schedule-add-icon {
        color: white;
        height: 18px;
        width: 18px;
        position: absolute;
        left: 13px;
        background: url("../../../assets/icons/add-icon.svg") no-repeat center
          center;
      }
    }
  }

  .job-schedule-calendar-container {
    margin-top: 25px;
    width: 100%;
    height: 1120px;
    margin-bottom: 40px;
    &.ipad {
      height: 100% !important;
    }
  }
}

.ewp-header-notification-popover-container {
  width: 100%;
  height: 100%;
  .pop-over-content {
    width: 100%;
    height: 100%;
    max-height: 220px;
    .empty-notification {
      padding: 20px;
      text-align: center;
    }
    .notifications-container {
      display: flex;
      flex-direction: column;
      padding: 10px;
      width: 100%;
      height: 100%;
      cursor: pointer;
      background-color: white;
      .note-message {
        max-width: 220px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .notifications-container:hover {
      background-color: var(--ion-color-hover);
    }
  }
}

.job-sched-loading {
  --backdrop-opacity: 0.3;
  .loading-wrapper {
    background: transparent !important;
    backdrop-filter: unset !important;
  }
}
